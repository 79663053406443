import axios from 'axios';
import { setNewCSRF } from './csrf-token.js';

export function registerAxiosInterceptor() {
    // Intercept all axios responses to catch every error.
    axios.interceptors.response.use((response) => {
        // If we're getting CSRF header back, compare and set new
        if (response.headers && response.headers['x-csrf-token']) {
            let currentToken = axios.defaults.headers.common['X-CSRF-TOKEN'];
            let respToken = response.headers['x-csrf-token'];

            if (currentToken !== respToken) {
                setNewCSRF(respToken);
            }
        }

        return response;
    }, (error) => {
        // No response - no internjet. We do not want to send this to sentry
        // OR if Sentry isn't enabled, we just return the rejected promise.
        if (!error.response || !window.Sentry) {
            return Promise.reject(error);
        }

        // Log to Sentry - First add response as additional info and then capture the actiual error
        // Wait 1 second before sending to Sentry, so we also get userdata if /api/user is slow
        setTimeout(() => {
            const res = error.response;
            const req = error.request;
            const conf = error.config;
            const url = conf ? conf.url : '';
            const status = req ? req.status : '';
            const statusText = req ? req.statusText : '';
            let errorMessage = '';

            // Skip logging 401 errors from ajax login
            if (conf.url === '/ajax/login' && status === 401) {
                return;
            }

            // Add laravel/BE custom error message if available
            if (res !== undefined && res.data && typeof res.data.status === 'object') {
                const status = res.data.status;

                if (status.error) {
                    errorMessage = status.error;
                } else if (status.message) {
                    errorMessage = status.message;
                }
            }

            try {
                Sentry.getCurrentScope().setLevel('error');

                if (errorMessage) {
                    Sentry.getCurrentScope().setExtra('Response Message:', errorMessage);
                }

                if (res) {
                    Sentry.getCurrentScope().setExtra('HTTP Response:', res);
                } else {
                    Sentry.getCurrentScope().setExtra('HTTP Response:', 'none');
                    Sentry.getCurrentScope().setExtra('HTTP Request:', req);
                }

                Sentry.getCurrentScope().setFingerprint([
                    url,
                    String(status),
                    statusText,
                    errorMessage,
                ]);

                Sentry.captureMessage(error.message);
            } catch (error) {
                console.warn('Sentry failed:  ', error);
            }
        }, 1000);

        // Return error and reject to trigger .catch where axios is used.
        return Promise.reject(error);
    });
}
