import { Environments, HiddenFeaturesByEnv } from '../config/featureConfig.js';
import { Features, FeaturesToggleLocalStorageKey } from '../config/features.js';

export default {
    computed: {
        featuresToggleValue() {
            return localStorage.getItem(FeaturesToggleLocalStorageKey) === 'true';
        },
    },
    methods: {
        isFeatureAvailable(feature) {
            let env = import.meta.env.VITE_APP_ENV ?? Environments.PRODUCTION;

            if (import.meta.env.VITE_APP_ENV !== Environments.PRODUCTION && !this.featuresToggleValue) {
                env = Environments.PRODUCTION;

                if (feature === Features.MAIN_NAV_FEATURES_TOGGLE) {
                    return true;
                }
            }

            const environmentCheck = HiddenFeaturesByEnv[env].includes(feature);

            return !environmentCheck;
        },
    },
};
