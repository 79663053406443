import axios from 'axios';
import { get, put, logThrowApiError } from '../../../libs/kk-api';

export default {
    namespaced: true,
    state: {
        loggedUser: null,
        loggedEmployee: null,
        isLoadingLoggedUser: false,
        team: null,
        position: null,
    },
    mutations: {
        UPDTE_LOGGED_USER(state, user) {
            state.loggedUser = user;
        },
        UPDTE_LOGGED_EMPLOYEE(state, user) {
            state.loggedEmployee = user;
        },
        UPDTE_USER_TEAM(state, team) {
            state.team = team;
        },
        UPDTE_USER_POSITION(state, position) {
            state.position = position;
        },
        UPDATE_IS_LOADING_LOGGED_USER(state, isLoading) {
            state.isLoadingLoggedUser = isLoading;
        },
    },
    actions: {
        async fetchLoggedUser({ commit, rootGetters }) {
            commit('UPDATE_IS_LOADING_LOGGED_USER', true);
            const userId = rootGetters.getUserId;

            try {
                const response = await get(`/v2/employees/${userId}`);
                commit('UPDTE_LOGGED_USER', response.user);
                commit('UPDTE_LOGGED_EMPLOYEE', response.employee);
                commit('UPDTE_USER_TEAM', response.employee.team);
                commit('UPDTE_USER_POSITION', response.employee.position);
            } catch (error) {
                logThrowApiError(error, `/v2/employees/${userId}`);
                console.error(error);
            } finally {
                commit('UPDATE_IS_LOADING_LOGGED_USER', false);
            }
        },
        async updateLoggedUser({ commit, rootGetters }, payload) {
            commit('UPDATE_IS_LOADING_LOGGED_USER', true);
            const userId = rootGetters.getUserId;

            try {
                const response = await put(`/v2/employees/${userId}`, payload);
                let user = response.user;
                user.password = '';
                commit('UPDTE_LOGGED_USER', user);
                commit('UPDTE_LOGGED_EMPLOYEE', response.employee);
                commit('UPDTE_USER_TEAM', response.employee.team);
                commit('UPDTE_USER_POSITION', response.employee.position);
            } catch (error) {
                logThrowApiError(error, `/v2/employees/${userId}`);
            } finally {
                commit('UPDATE_IS_LOADING_LOGGED_USER', false);
            }
        },
        async clearSession() {
            try {
                await axios.post('/ajax/clear-session');
            } catch (error) {
                console.error(error);
            }
        },
    },
};
