export const helpers = {
    methods: {
        /**
         * Returns what percentage the share represents of the total
         * @param {Number} share
         * @param {Number} total
         * @returns {Number} the percentage
         */
        percentageOf(share, total) {
            return (share / total) * 100;
        },
    },
    computed: {
        languages() {
            return [
                {
                    value: 'nb',
                    label: this.trans('hr.Norsk'),
                },
                {
                    value: 'en',
                    label: this.trans('hr.Engelsk'),
                },
                {
                    value: 'pl',
                    label: this.trans('hr.Polsk'),
                },
                {
                    value: 'de',
                    label: this.trans('hr.Tysk'),
                },
                {
                    value: 'lt',
                    label: this.trans('hr.Litauisk'),
                },
                {
                    value: 'ru',
                    label: this.trans('hr.Russisk'),
                },
            ];
        },
    },
};
