import { Features } from './features';

/**
 *  PR/Beta = “staging”
    Production = “production”
    Local/Dev = “local”
 */
export const Environments = Object.freeze({
    LOCAL: 'local',
    STAGING: 'staging',
    PRODUCTION: 'production',
});

export const HiddenFeaturesByEnv = Object.freeze({
    [Environments.LOCAL]: [
        Features.MOBILE_DASHBOARD_STATISTIC_TIME_TRACKING_WIDGET,
        Features.MOBILE_DASHBOARD_STATISTIC_CHECKLIST_WIDGET,
        Features.MOBILE_DASHBOARD_STATISTIC_DEVIATIONS_WIDGET,
        Features.MOBILE_DASHBOARD_STATISTIC_PROCEDURES_WIDGET,
    ],
    [Environments.STAGING]: [
        Features.MOBILE_DASHBOARD_STATISTIC_TIME_TRACKING_WIDGET,
        Features.MOBILE_DASHBOARD_STATISTIC_CHECKLIST_WIDGET,
        Features.MOBILE_DASHBOARD_STATISTIC_DEVIATIONS_WIDGET,
        Features.MOBILE_DASHBOARD_STATISTIC_PROCEDURES_WIDGET,
    ],
    [Environments.PRODUCTION]: [
        Features.MAIN_NAV_FEATURES_TOGGLE,
        Features.MOBILE_DASHBOARD,
        Features.MOBILE_DASHBOARD_STATISTIC_TIME_TRACKING_WIDGET,
        Features.MOBILE_DASHBOARD_STATISTIC_CHECKLIST_WIDGET,
        Features.MOBILE_DASHBOARD_STATISTIC_DEVIATIONS_WIDGET,
        Features.MOBILE_DASHBOARD_STATISTIC_PROCEDURES_WIDGET,
    ],
});
